import React from 'react';
import { applyMiddleware, createStore as reduxCreateStore } from 'redux';
import { Provider } from 'react-redux';
import promise from 'redux-promise-middleware';
import rootReducer from '.';

// const loggerMiddleware = createLogger();

const middlewares = [promise];

// if (process.env.NODE_ENV === 'development') {
// 	middlewares.push();
// }

const createStore = () => reduxCreateStore(rootReducer, applyMiddleware(...middlewares));

// eslint-disable-next-line react/display-name
export default ({ element }) => <Provider store={createStore()}>{element}</Provider>;
