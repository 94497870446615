import config from '../config';
import customAxios from '../contants/customAxios';

export const refreshToken = () => customAxios.get(`${config.API_URL}app/refresh`);
export const fetchEventByHash = (hash) => customAxios.get(`${config.API_URL}app/event/byHash/${hash}`);
export const loginAuthorizeToEvent = (hash, data) => customAxios.post(`${config.API_URL}app/event/${hash}/authorize`, data);
export const codeAuthorizeToEvent = (hash: string, code: string) =>
	customAxios.post(`${config.API_URL}app/event/${hash}/authorize/code`, { code });
export const passwordAuthorizeToEvent = (hash, data) =>
	customAxios.post(`${config.API_URL}app/event/${hash}/authorize/password`, data);

export const resetPassword = (hash, data) =>
	customAxios.post(`${config.API_URL}app/event/${hash}/remind/password/reset`, data);
export const resetCodeAuthorizeToEvent = (hash: string, login: string) =>
	customAxios.post(`${config.API_URL}app/event/${hash}/remind/code/reset`, { login });
export const resetCodeVerify = (hash: string, login: string, code: string) =>
	customAxios.post(`${config.API_URL}app/event/${hash}/remind/code/verify`, {
		login,
		code,
	});

export const fetchEventDetails = () => customAxios.get(`${config.API_URL}app/event/details`);
export const fetchAllUsers = () => customAxios.get(`${config.API_URL}app/event/users`);
export const fetchEventRooms = () => customAxios.get(`${config.API_URL}app/event/configuration`);
export const sendSupportEmail = (email, text) => customAxios.post(`${config.API_URL}app/support`, {
	email,
	text,
});

export const getUserDetails = () => customAxios.get(`${config.API_URL}app/userDetails`);

export const fetchVotingModule = (votingModuleId) => customAxios.get(`${config.API_URL}app/voting-module/${votingModuleId}`);
export const sendAnswer = (votingModuleId, questionId, answers) => (
	customAxios.post(`${config.API_URL}app/voting-module/${votingModuleId}/questions/${questionId}`, { answers }));
export const sendAnswerEdit = (votingModuleId, questionId, answers) => (
	customAxios.put(`${config.API_URL}app/voting-module/${votingModuleId}/questions/${questionId}`, { answers }));
export const getAnswer = (votingModuleId, questionId, principalId = null) => (
	customAxios.get(
		`${config.API_URL}app/voting-module/${votingModuleId}/questions/${questionId}`,
		{
			params: {
				principalAccessId: principalId || null,
			},
		},
	));

export const fetchScoreboard = (votingModuleId) => customAxios.get(`${config.API_URL}app/voting-module/${votingModuleId}/scoreboard`);

export const fetchStreamingData = () => customAxios.get(`${config.API_URL}app/event/streaming`);
export const fetchChatHistory = (lastId, limit) =>
	customAxios.get(`${config.API_URL}app/event/chat`, {
		params: {
			lastId,
			limit,
		},
	});
export const fetchChatMembers = () => customAxios.get(`${config.API_URL}app/event/activeUsers`);
export const fetchEventFiles = () => customAxios.get(`${config.API_URL}app/event/files`);
export const sendEmailWithFiles = (email: string, filesIds: Array<string>) =>
	customAxios.post(`${config.API_URL}app/event/files`, {
		email,
		filesIds,
	});

export const getWsToken = () => customAxios.get(`${config.API_URL}app/ws/token`);
export const removeWsToken = () => customAxios.delete(`${config.API_URL}app/ws/token`);
