import { fetchEventRooms } from '../services/services';
import { RoomConfigState } from './@types/roomConfig.interface';

const initialState: RoomConfigState = {
	agenda: false,
	chat: false,
	isGeneralMeeting: false,
	video: false,
	attendanceList: true,
};

enum ActionType {
	SetConfig = 'SET_CONFIG',
	SetAgendaStatus = 'SET_AGENDA_STATUS',
	FETCH_EVENT_ROOMS = 'FETCH_EVENT_ROOMS',
}

export const setRoomConfigAction = (config: RoomConfigState) => ({
	type: ActionType.SetConfig,
	config,
});

export const fetchEventRoomsAction = () => ({
	type: ActionType.FETCH_EVENT_ROOMS,
	payload: fetchEventRooms(),
});

export const setAgendaStatusAction = (status: boolean) => ({
	type: ActionType.SetAgendaStatus,
	status,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case ActionType.SetConfig:
			return { ...state };
		case ActionType.SetAgendaStatus:
			return {
				...state,
				agenda: action.status,
			};
		case `${ActionType.FETCH_EVENT_ROOMS}_FULFILLED`:
			return {
				attendanceList: true,
				...action?.payload?.data,
			};
		default:
			return { ...state };
	}
};
