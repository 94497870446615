import { fetchEventByHash, fetchEventDetails } from '../services/services';

const initialState = {
	loading: false,
	error: false,
	data: null,
	details: null,
	voters: null,
};

const FETCH_EVENT = 'FETCH_EVENT';
const FETCH_EVENT_DETAILS = 'FETCH_EVENT_DETAILS';
const SET_EVENT_LOADING_STATUS = 'SET_EVENT_LOADING_STATUS';

export const fetchEventByHashAction = (hash: string) => ({
	type: FETCH_EVENT,
	payload: fetchEventByHash(hash),
});

export const fetchEventDetailsAction = () => ({
	type: FETCH_EVENT_DETAILS,
	payload: fetchEventDetails(),
});

export const setEventLoadingStatusAction = (status: boolean) => ({
	type: SET_EVENT_LOADING_STATUS,
	status,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_EVENT_LOADING_STATUS:
			return {
				...state,
				loading: action.status,
			};
		case `${FETCH_EVENT}_PENDING`:
			return {
				...initialState,
				loading: true,
				error: false,
			};
		case `${FETCH_EVENT}_REJECTED`:
			return {
				...initialState,
				loading: false,
				error: true,
			};
		case `${FETCH_EVENT}_FULFILLED`:
			return {
				...initialState,
				data: action?.payload?.data,
				error: false,
			};
		case `${FETCH_EVENT_DETAILS}_PENDING`:
			return {
				...state,
				loading: !state.details,
			};
		case `${FETCH_EVENT_DETAILS}_REJECTED`:
			return {
				...state,
				loading: false,
				error: true,
			};
		case `${FETCH_EVENT_DETAILS}_FULFILLED`:
			if (action?.payload?.data) {
				return {
					...state,
					loading: false,
					details: action.payload.data,
				};
			}
			return { ...state };

		default:
			return { ...state };
	}
};
