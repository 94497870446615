import { fetchStreamingData } from '../services/services';
import { AlertInterface } from '../components/room/videoChat/streaming/@types/alert.interface';
import { makeId } from '../_utils/makeId';
import { StreamingState } from './@types/streaming.interface';

const initialState: StreamingState = {
	loading: false,
	error: false,
	fetched: true,
	isEmailAuth: {
		uid: null,
		appId: null,
		channel: null,
		rtcToken: null,
		userRole: null,
	},
	alerts: [],
};

const FETCH_STREAMING_DATA = 'FETCH_STREAMING_DATA';
const SHOW_NEW_ALERT = 'SHOW_NEW_ALERT';
const DISMISS_ALERT = 'DISMISS_ALERT';
const CLEAR_ALERTS = 'CLEAR_ALERTS';

export const fetchStreamingDataAction = () => ({
	type: FETCH_STREAMING_DATA,
	payload: fetchStreamingData(),
});
export const showNewAlertAction = (notificationData: AlertInterface) => ({
	type: SHOW_NEW_ALERT,
	payload: {
		...notificationData,
		alertId: makeId(5),
	},
});
export const dismissAlertAction = (alertId: string) => ({
	type: DISMISS_ALERT,
	payload: alertId,
});
export const clearAlertsAction = () => ({
	type: CLEAR_ALERTS,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_STREAMING_DATA}_PENDING`:
			return {
				...state,
				loading: true,
				fetched: false,
			};
		case `${FETCH_STREAMING_DATA}_REJECTED`:
			return {
				...state,
				error: true,
				fetched: false,
			};
		case `${FETCH_STREAMING_DATA}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				fetched: true,
				data: action.payload?.data,
			};
		case SHOW_NEW_ALERT:
			return {
				...state,
				alerts: [...state.alerts, action.payload],
			};
		case DISMISS_ALERT:
			return {
				...state,
				alerts: state.alerts.filter((alert) => alert.alertId !== action.payload),
			};
		case CLEAR_ALERTS:
			return {
				...state,
				alerts: [],
			};
		default:
			return { ...state };
	}
};
