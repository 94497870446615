exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-authorize-tsx": () => import("./../../../src/pages/authorize.tsx" /* webpackChunkName: "component---src-pages-authorize-tsx" */),
  "component---src-pages-event-ended-tsx": () => import("./../../../src/pages/eventEnded.tsx" /* webpackChunkName: "component---src-pages-event-ended-tsx" */),
  "component---src-pages-forgotten-password-tsx": () => import("./../../../src/pages/forgottenPassword.tsx" /* webpackChunkName: "component---src-pages-forgotten-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-logged-out-tsx": () => import("./../../../src/pages/loggedOut.tsx" /* webpackChunkName: "component---src-pages-logged-out-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-room-tsx": () => import("./../../../src/pages/room.tsx" /* webpackChunkName: "component---src-pages-room-tsx" */)
}

