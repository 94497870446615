import { combineReducers } from 'redux';
import event from './event.reducer';
import authorize from './authorize.reducer';
import votingModule from './votingModule.reducer';
import scoreboard from './scoreboard.reducer';
import streaming from './streaming.reducer';
import chat from './chat.reducer';
import roomConfig from './roomConfig.reducer';
import files from './files.reducer';
import menuTab from './menuTab.reducer';
import userPermissions from './userPermissions.reducer';

export default combineReducers({
	event,
	authorize,
	votingModule,
	scoreboard,
	streaming,
	chat,
	roomConfig,
	files,
	menuTab,
	userPermissions,
});
