import {
	codeAuthorizeToEvent, loginAuthorizeToEvent, passwordAuthorizeToEvent,
} from '../services/services';
import { AuthorizeState } from './@types/authorize.interface';

const initialState: AuthorizeState = {
	loginLoading: false,
	codeLoading: false,
	passwordLoading: false,
	error: false,
	responseError: null,
	isPasswordLoading: false,
	isPasswordSet: null,
	isLoginAuth: null,
	isCodeAuth: null,
	voterEventAccess: null,
	login: '',
};
const SET_LOADING_PASSWORD = 'SET_LOADING_PASSWORD';
const LOGIN_AUTHORIZE_TO_EVENT = 'LOGIN_AUTHORIZE_TO_EVENT';
const CODE_AUTHORIZE_TO_EVENT = 'CODE_AUTHORIZE_TO_EVENT';
const PASSWORD_AUTHORIZE_TO_EVENT = 'PASSWORD_AUTHORIZE_TO_EVENT';
const SET_LOGIN = 'SET_LOGIN';

export const loadingPassword = (status: boolean) => ({
	type: SET_LOADING_PASSWORD,
	status,
});
export const loginAuthorizeToEventAction = (hash, data) => ({
	type: LOGIN_AUTHORIZE_TO_EVENT,
	payload: loginAuthorizeToEvent(hash, data),
});
export const codeAuthorizeToEventAction = (hash, data) => ({
	type: CODE_AUTHORIZE_TO_EVENT,
	payload: codeAuthorizeToEvent(hash, data),
});
export const passwordAuthorizeToEventAction = (hash, data) => ({
	type: PASSWORD_AUTHORIZE_TO_EVENT,
	payload: passwordAuthorizeToEvent(hash, data),
});
export const setLoginAction = (login: string) => ({
	type: SET_LOGIN,
	login,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case `${LOGIN_AUTHORIZE_TO_EVENT}_PENDING`:
			return {
				...initialState,
				loginLoading: true,
			};
		case `${LOGIN_AUTHORIZE_TO_EVENT}_REJECTED`:
			return {
				...initialState,
				loginLoading: false,
				error: action?.payload?.response?.status === 410 ? 410 : true,
			};
		case `${LOGIN_AUTHORIZE_TO_EVENT}_FULFILLED`:
			return {
				...initialState,
				loginLoading: false,
				isPasswordSet: action?.payload?.data.isPasswordSet,
				isLoginAuth: true,
			};
		case `${CODE_AUTHORIZE_TO_EVENT}_PENDING`:
			return {
				...initialState,
				codeLoading: true,
			};
		case `${CODE_AUTHORIZE_TO_EVENT}_REJECTED`:
			return {
				...initialState,
				codeLoading: false,
				error: true,
				responseError: action?.payload?.response?.data,
			};
		case `${CODE_AUTHORIZE_TO_EVENT}_FULFILLED`:
			return {
				...initialState,
				codeLoading: false,
				isCodeAuth: true,
				responseError: null,
			};
		case `${PASSWORD_AUTHORIZE_TO_EVENT}_PENDING`:
			return {
				...initialState,
				passwordLoading: true,
			};
		case `${PASSWORD_AUTHORIZE_TO_EVENT}_REJECTED`:
			return {
				...initialState,
				passwordLoading: false,
				error: true,
			};
		case `${PASSWORD_AUTHORIZE_TO_EVENT}_FULFILLED`:
			return {
				...initialState,
				passwordLoading: false,
				voterEventAccess: action?.payload?.data,
			};
		case SET_LOGIN:
			return {
				...initialState,
				login: action.login,
			};
		case SET_LOADING_PASSWORD:
			return {
				...state,
				isPasswordLoading: action.status,
			};
		default:
			return { ...state };
	}
};
