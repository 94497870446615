import axios from 'axios';
import { navigate } from 'gatsby';
import config from '../config';
import { isWindow } from '../_utils/windowUndefined';

const instance = axios.create();

instance.defaults.withCredentials = true;
instance.interceptors.response.use((res) => res,
	(error) => {
		if (error?.response?.status !== 401 && error?.status !== 401) {
			return Promise.reject(error);
		}
		return instance.get(`${config.API_URL}app/refresh`)
			.then(() => {
				axios(error.response.config);
			})
			.catch(() => {
				navigate('/loggedOut');
				if (isWindow) {
					/* eslint-disable */
					// @ts-ignore
					window?.client?.leave();
					// @ts-ignore
					window?.socketio?.emit("lowerHand");
				}
			});
	});

export default instance;
