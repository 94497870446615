import moment from 'moment';
import { fetchVotingModule, sendAnswer, sendAnswerEdit } from '../services/services';
import { VotingModuleState } from './@types/votingModule.interface';
import { QuestionDto } from './@types/question.interface';

const initialState: VotingModuleState = {
	loading: false,
	votingId: null,
	error: false,
	votingModule: null,
	question: null,
	questionIndex: 0,
	questionEndTime: null,
	isEditMode: false,
	proxy: {
		chosenIndex: -1,
		members: [],
	},
};

const FETCH_VOTING_MODULE = 'FETCH_VOTING_MODULE';
const SEND_ANSWER = 'SEND_ANSWER';
const SEND_TIME_TRACKING_ANSWER = 'SEND_TIME_TRACKING_ANSWER';
const TIME_EXCEEDED = 'TIME_EXCEEDED';
const SET_VOTING_ID = 'SET_VOTING_ID';
const SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS';
const SET_QUESTION_INDEX = 'SET_QUESTION_INDEX';
const SET_QUESTION = 'SET_QUESTION';
const SET_ATTORNEYS = 'SET_ATTORNEYS';
const CHOOSE_ATTORNEY = 'CHOOSE_ATTORNEY';

export const fetchVotingModuleAction = (votingModuleId) => ({
	type: FETCH_VOTING_MODULE,
	payload: fetchVotingModule(votingModuleId),
});

export const sendAnswerAction = (votingModuleId, questionId, isEdit, data) => ({
	type: SEND_ANSWER,
	payload: isEdit ? sendAnswerEdit(votingModuleId, questionId, data) : sendAnswer(votingModuleId, questionId, data),
});

export const sendTimeTrackingAnswerAction = (votingModuleId, questionId, isEdit, data) => ({
	type: SEND_TIME_TRACKING_ANSWER,
	payload: isEdit ? sendAnswerEdit(votingModuleId, questionId, data) : sendAnswer(votingModuleId, questionId, data),
});

export const timeExceededAction = (data) => ({
	type: TIME_EXCEEDED,
	data,
});

export const setVotingIdAction = (id: string) => ({
	type: SET_VOTING_ID,
	id,
});

export const setQuestionIndexAction = (id: number) => ({
	type: SET_QUESTION_INDEX,
	id,
});
export const setQuestionAction = (question: QuestionDto) => ({
	type: SET_QUESTION,
	question,
});

export const setEditModeStatusAction = (status: boolean) => ({
	type: SET_EDITABLE_STATUS,
	status,
});

export const setAttorneysAction = (members) => ({
	type: SET_ATTORNEYS,
	members,
});
export const chooseAttorneyIndexAction = (member: number) => ({
	type: CHOOSE_ATTORNEY,
	chosenProxy: member,
});

const onFulfilled = (state, action) => {
	let question;
	let { questionIndex } = state;
	const didVoted = state.votingModule?.didVoted?.every((votingStatus) => votingStatus.didVoted);
	if (!state.isEditMode && didVoted) {
		question = action.payload.data.questions.find((q) => !q.answered) || null;
		questionIndex = action.payload.data.questions.findIndex((q) => !q.answered) || null;
	} else {
		question = { ...action.payload.data.questions[questionIndex] };
		questionIndex = 0;
	}
	const questionEndTime = question && question.time ? moment(new Date()).add(question.time, 's') : null;
	return {
		...state,
		votingModule: action.payload.data,
		question,
		questionIndex,
		questionEndTime,
		loading: false,
		error: false,
	};
};

const onTimeExceeded = (state, data) => {
	const questionIndex = state.questionIndex + 1;
	const question = state.votingModule.questions[questionIndex];
	if (state.question.id && data?.length > 0) {
		sendAnswer(state.votingId, state.question?.id, data);
	}
	return {
		...state,
		questionIndex,
		question,
		questionEndTime: question && question.time ? moment(new Date()).add(question.time, 's') : null,
	};
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_ATTORNEYS:
			return {
				...state,
				proxy: {
					...state.proxy,
					members: action.members,
				},
			};
		case CHOOSE_ATTORNEY:
			return {
				...state,
				proxy: {
					...state.proxy,
					chosenIndex: action.chosenProxy,
				},
			};
		case SET_VOTING_ID:
			return {
				...state,
				votingId: action.id,
			};
		case SET_EDITABLE_STATUS:
			return {
				...state,
				isEditMode: action.status,
			};
		case SET_QUESTION_INDEX:
			return {
				...state,
				questionIndex: action.id,
			};
		case SET_QUESTION:
			return {
				...state,
				question: action.question,
			};
		case `${FETCH_VOTING_MODULE}_PENDING`:
			return {
				...state,
				loading: true,
			};
		case `${FETCH_VOTING_MODULE}_REJECTED`:
			return {
				...state,
				error: true,
			};
		case `${FETCH_VOTING_MODULE}_FULFILLED`:
			if (action?.payload?.data) {
				return onFulfilled(state, action);
			}
			return { ...state };

		case `${SEND_ANSWER}_PENDING`:
			return {
				...state,
				loading: true,
			};
		case `${SEND_ANSWER}_REJECTED`:
			return {
				...state,
				error: true,
			};
		case `${SEND_ANSWER}_FULFILLED`:
		case TIME_EXCEEDED:
			return onTimeExceeded(state, action.data);
		default:
			return { ...state };
	}
};
