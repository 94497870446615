import { MenuPopupsEnum } from '../_enums/menuPopupsEnum';
import { MenuTabState } from './@types/menuTab.interface';

const initialState: MenuTabState = {
	activeSubPage: null,
};

const CHANGE_ACTIVE_MENU_SUBPAGE = 'CHANGE_ACTIVE_MENU_SUBPAGE';

export const changeActiveMenuSubpage = (subpage: MenuPopupsEnum) => ({
	type: CHANGE_ACTIVE_MENU_SUBPAGE,
	subpage,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_ACTIVE_MENU_SUBPAGE:
			return {
				...initialState,
				activeSubPage: action.subpage,
			};
		default:
			return { ...state };
	}
};
