import { fetchEventFiles } from '../services/services';
import { FilesState } from './@types/files.interface';

enum Files {
	SetFiles,
	ShowFile,
}

export const setFiles = (files) => ({
	type: Files.SetFiles,
	files,
});

export const showFile = (file) => ({
	type: Files.ShowFile,
	file,
});
const initialState: FilesState = {
	files: [],
	shownFile: null,
};
const FETCH_FILES = 'FETCH_EVENT_FILES';

export const fetchEventFilesAction = () => ({
	type: FETCH_FILES,
	payload: fetchEventFiles(),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_FILES}_REJECTED`:
			return {
				...initialState,
				files: action.shownFile,
			};
		case `${FETCH_FILES}_FULFILLED`:
			return {
				...initialState,
				files: action?.payload?.data,
			};

		case Files.SetFiles:
			return {
				...state,
				files: action.files,
			};
		case Files.ShowFile:
			return {
				...state,
				shownFile: action.file,
			};
		default:
			return { ...state };
	}
};
