import { fetchScoreboard } from '../services/services';

const initialState = {
	loading: false,
	error: false,
	data: {
		questions: [],
		type: null,
		numberOfVoters: null,
	},
};

const FETCH_SCOREBOARD = 'FETCH_SCOREBOARD';

export const fetchScoreboardAction = (votingModuleId) => ({
	type: FETCH_SCOREBOARD,
	payload: fetchScoreboard(votingModuleId),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_SCOREBOARD}_PENDING`:
			return {
				...state,
				loading: true,
			};
		case `${FETCH_SCOREBOARD}_REJECTED`:
			return {
				...state,
				error: true,
			};
		case `${FETCH_SCOREBOARD}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				data: action.payload?.data,
			};
		default:
			return { ...state };
	}
};
