import { fetchAllUsers } from '../services/services';
import { ChatState } from './@types/chat.interface';

const initialState: ChatState = {
	socket: null,
	users: [],
	message: '',
	messages: [],
	voters: [],
	unreadMessages: 0,
	loudestUser: {
		uid: null,
		volume: 0,
	},
};
const SET_LOUDEST_USER = 'SET_LOUDEST_USER';
const RESET_UNREAD = 'RESET_UNREAD';
const SET_MESSAGE = 'SET_MESSAGE';
const ADD_MESSAGE = 'ADD_MESSAGE';
const LOAD_MESSAGES = 'LOAD_MESSAGES';
const SET_SOCKET = 'SET_SOCKET';
const SET_USERS = 'SET_USERS';
const ADD_USER = 'ADD_USER';
const REMOVE_USER = 'REMOVE_USER';
const SET_MICROPHONE_STATE = 'SET_MICROPHONE_STATE';
const RAISE_HAND = 'RAISE_HAND';
const LOWER_HAND = 'LOWER_HAND';
const CALL_JOINED = 'CALL_JOINED';
const CALL_LEAVE = 'CALL_LEAVE';
const RESET_USERS_STATUS_ON_LIST = 'RESET_USERS_STATUS_ON_LIST';
const FETCH_ALL_EVENT_USERS = 'FETCH_ALL_EVENT_USERS';

export const setLoudestUserAction = (uid: string | number, volume: string | number) => ({
	type: SET_LOUDEST_USER,
	payload: {
		uid,
		volume,
	},
});

export const resetUnreadAction = () => ({
	type: RESET_UNREAD,
});

export const addMessageAction = (voterEventAccessId, sender, message) => ({
	type: ADD_MESSAGE,
	payload: {
		voterEventAccessId,
		sender,
		message,
	},
});

export const setMessageAction = (text) => ({
	type: SET_MESSAGE,
	payload: text,
});

export const loadMessagesAction = (messages) => ({
	type: LOAD_MESSAGES,
	messages,
});

export const setSocketAction = (socket) => ({
	type: SET_SOCKET,
	socket,
});
export const addUserAction = (user) => ({
	type: ADD_USER,
	user,
});
export const setUsersAction = (users) => ({
	type: SET_USERS,
	users,
});
export const removeUserAction = (userId) => ({
	type: REMOVE_USER,
	userId,
});
export const setUserMicrophoneState = (userId, isMuted: boolean) => ({
	type: SET_MICROPHONE_STATE,
	payload: {
		userId,
		isMuted,
	},
});
export const raiseHandAction = (userId) => ({
	type: RAISE_HAND,
	userId,
});
export const lowerHandAction = (userId) => ({
	type: LOWER_HAND,
	userId,
});
export const joinCallAction = (userId) => ({
	type: CALL_JOINED,
	userId,
});
export const leaveCallAction = (userId) => ({
	type: CALL_LEAVE,
	userId,
});
export const fetchAllEventUsers = () => ({
	type: FETCH_ALL_EVENT_USERS,
	payload: fetchAllUsers(),
});

export const resetUsersStatusOnListAction = () => ({
	type: RESET_USERS_STATUS_ON_LIST,
});
export default (state = initialState, action) => {
	switch (action.type) {
		case SET_MESSAGE:
			return {
				...state,
				message: action.payload,
			};
		case SET_USERS:
			return {
				...state,
				users: [...action.users],
			};
		case LOAD_MESSAGES:
			return {
				...state,
				messages: [...action.messages, ...state.messages],
			};
		case ADD_USER:
			return {
				...state,
				users: [...state.users, action.user],
			};
		case REMOVE_USER:
			return {
				...state,
				users: state.users.filter((el) => el.voterEventAccessId !== action.userId.voterEventAccessId),
			};
		case SET_SOCKET:
			return {
				...state,
				socket: action.socket,
			};
		case ADD_MESSAGE:
			return {
				...state,
				unreadMessages: state.unreadMessages + 1,
				messages: [
					...state.messages,
					{
						voterEventAccessId: action.payload.voterEventAccessId,
						message: action.payload.message,
						sender: action.payload.sender,
					},
				],
			};
		case SET_MICROPHONE_STATE:
			try {
				state.users.find((user) => user.voterEventAccessId === action.payload.userId).isMuted = action.payload.isMuted;
			} catch (e) {
				console.log(`User ${action.payload.userId} not found on chat list`);
			}
			return {
				...state,
				users: state.users,
			};
		case RAISE_HAND:
			try {
				state.users.find((user) => user.voterEventAccessId === action.userId).handRaised = true;
			} catch (e) {
				console.log(`User ${action.userId} not found on chat list`);
			}
			return {
				...state,
				users: state.users,
			};
		case LOWER_HAND:
			try {
				state.users.find((user) => user.voterEventAccessId === action.userId).handRaised = false;
			} catch (e) {
				console.log(`User ${action.userId} not found on chat list`);
			}
			return {
				...state,
				users: state.users,
			};
		case CALL_JOINED:
			try {
				state.users.find((user) => user.voterEventAccessId === action.userId).isInCall = true;
			} catch (e) {
				console.log(`User ${action.userId} not found on chat list`);
			}
			return {
				...state,
				users: state.users,
			};
		case CALL_LEAVE:
			try {
				state.users.find((user) => user.voterEventAccessId === action.userId).isInCall = false;
			} catch (e) {
				console.log(`User ${action.userId} not found on chat list`);
			}
			return {
				...state,
				users: state.users,
			};
		case RESET_UNREAD:
			return {
				...state,
				unreadMessages: 0,
			};
		case RESET_USERS_STATUS_ON_LIST: {
			const newUsersList = [...state.users];
			newUsersList.forEach((value) => {
				value.isInCall = false;
				value.handRaised = false;
			});
			return {
				...state,
				users: newUsersList,
			};
			break;
		}
		case `${FETCH_ALL_EVENT_USERS}_PENDING`:
			return {
				...state,
				loading: true,
				error: false,
			};
		case `${FETCH_ALL_EVENT_USERS}_REJECTED`:
			return {
				...state,
				loading: false,
				error: true,
			};
		case `${FETCH_ALL_EVENT_USERS}_FULFILLED`:
			return {
				...state,
				voters: action?.payload?.data,
				error: false,
			};
		case SET_LOUDEST_USER: {
			return {
				...state,
				loudestUser: {
					uid: action.payload.uid,
					volume: action.payload.volume,
				},
			};
		}
		default:
			return { ...state };
	}
};
