import { getUserDetails } from '../services/services';
import { UserPermissionsState } from './@types/userPermissions.interface';

const initialState: UserPermissionsState = {
	canVote: false,
};

const FETCH_USER_PERMISSIONS = 'FETCH_USER_PERMISSIONS';

export const fetchUserPermissions = () => ({
	type: FETCH_USER_PERMISSIONS,
	payload: getUserDetails(),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_USER_PERMISSIONS}_FULFILLED`:
			return {
				...initialState,
				code: action.payload?.data?.code,
				...action.payload?.data?.role,
			};
		default:
			return { ...state };
	}
};
